<!-- 往届学生信息 -- 成绩记录 -->
<template>
  <div>
    <list-template
        :loading="loading"
        :search-config="searchConfig"
        @onSearch="onSearch"
        :table-config="tableConfig"
        :table-data="tableData"
        :total="total"
        :current-page="page"
        has-back
        @onChangePage="handleCurrentChange"
        @onHandle="handleEdit"
    ></list-template>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
	_config:{"route":{"path":"grade","meta":{"title":"往届成绩记录"}}},
  data() {
    return {
      loading: false,
      search:{},
      searchConfig:[
        {
          prop:"exam_name",
          tag: 'input',
          placeholder:"请输入考试名称",
        },
        {
          prop:"type_id",
          tag: 'select',
          placeholder:"选择考试类型",
          reserveKeyword: true,
          filterable: true,
          remote: true,
          remoteMethod: (e) => {
            this.getExam(e)
          },
          label: 'name',
          value: 'id',
          options: [],
        },
      ],
      tableData: [],
      //总条数
      total: 0,
      pageCount: 0,

      tableConfig:[
        {label: '校区', prop: 'school_name'},
        {label: '年级', prop: 'grade_name'},
        {label: '学号', prop: 'student_no'},
        {label: '考试名称', prop: 'exam_name',minWidth: 100},
        {label: '考试类型', prop: 'type_name',minWidth: 100},
        {label: '总成绩', prop: 'total_score'},
        {label: '数学', prop: 'math'},
        {label: '语文', prop: 'chinese'},
        {label: '外语', prop: 'english'},
        {label: '政治', prop: 'politics'},
        {label: '历史', prop: 'history'},
        {label: '地理', prop: 'geography'},
        {label: '物理', prop: 'physics'},
        {label: '化学', prop: 'chemistry'},
        {label: '生物', prop: 'biology'},
      ],
    }
  },
  created() {
    this.$store.commit("setPage",1);
    this.getData()
    this.getExam('')
  },
  activated() {
    this.getData()
  },
  computed:{
    ...mapState(["page"])
  },
  methods: {
    getData(){
      let params = {
        id: this.$route.query.id,
        page: this.page,
        ...this.search
      }
      this.$_axios2.get("/api/student-rereading/index/exam" , {params}).then(res => {
        let data = res.data.data
        this.tableData = data.list
        this.total = data.page.total
      }).finally(() => {
        this.loading = false
      })
    },

    getExam(e){
      this.$_axios2.get("/api/student-rereading/index/exam-type?name=" + e).then(res => {
        this.searchConfig[1].options = res.data.data
      })
    },

    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },

    onSearch(val){
      this.loading = true
      this.$store.commit('setPage', 1);
      this.search = val;
      this.getData(val)
    },

    handleEdit(row) {
      this.$router.push('./details?id=' + row.id)
    },

    // 当前页改变触发
    handleCurrentChange(val) {
      //当前页赋值给currentPage
      this.$store.commit("setPage",val);
      //拉取数据
      this.getData()
    },
  },
}
</script>

<style lang="scss" scoped>
.role-list {
  padding-top: 24rem;
}
.button {
  margin-left: 34rem;
  margin-bottom: 30rem;
  box-sizing: border-box;
  width: 90rem;
  height: 34rem;
  opacity: 1;
  border-radius: 4rem;
}
.table {
  margin-left: 32rem;
}


::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
  text-align: left;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  height: 48rem;
  box-sizing: border-box;
  color: #333333;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgba(24, 24, 120);
}
.header {
  position: fixed;
  top: 120rem;
  left: 300rem;
  font-size: 16rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  line-height: 24rem;
  color: #333333;
  letter-spacing: 3.5rem;
}
.el-button--text {
  margin-right: 40rem;
  font-size: 14rem;
  font-family: Microsoft YaHei;
  font-weight: normal;
  color: #3491fa;
}
</style>
